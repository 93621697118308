<template>
	<!-- 创建弹框 -->
	<el-dialog
		width="70%"
		center
		:title="organizeParams.title"
		:visible.sync="dialogVisible"
		:before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@open="openDialog"
	>
		<!-- <el-form :inline="true" :model="searchForm" size="small ">
      <el-form-item label="姓名">
        <el-input v-model="searchForm.staffName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="searchForm.phoneNumber" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>-->
		<div class="search_box">
			<el-input placeholder="请输入姓名或手机号查询" v-model.trim="keyWork" style="width: 40%" @keyup.enter.native="query">
				<i slot="suffix" class="el-input__icon el-icon-search search_icon" @click="query"></i>
			</el-input>
			<!--  <span class="el-icon-search" @click = "query">qqq</span> -->
		</div>
		<el-table
			ref="multipleTable"
			:data="staffList"
			tooltip-effect="dark"
			:row-key="getRowKey"
			v-loading="loading"
			style="width: 100%"
			class="tableBackground"
			@selection-change="handleSelectChange"
		>
			<el-table-column type="selection" :reserve-selection="true" width="60" align="center"></el-table-column>
			<el-table-column prop="staffName" label="姓名" width align="center"></el-table-column>
			<el-table-column prop="phoneNumber" label="手机号" width align="center"></el-table-column>
			<el-table-column prop="jobName" label="岗位" width align="center"></el-table-column>
			<el-table-column prop="jobNumber" label="工号" width align="center"></el-table-column>
			<el-table-column prop="mailbox" label="工作邮箱" width="200" align="center"></el-table-column>
		</el-table>
		<div style="display: flex; justify-content: flex-end">
			<el-pagination
				style="margin: 20px 0"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-size="10"
				background
				layout="total,prev, pager, next"
				:total="totalNum"
			></el-pagination>
		</div>

		<!-- <div> class="fr" :disabled="disabled"-->
		<span slot="footer" class="dialog-footer">
			<el-button @click="cancle">取 消</el-button>
			<el-button type="primary" @click="commitEnter">调整</el-button>
		</span>
		<!-- </div> -->

		<!-- 调整组织弹框 -->
		<move-dialog
			:dialogVisible.sync="moveDialog"
			:organizeList="organizeList"
			:lable="title"
			:organzieId="organizeParams.id"
			:selectList="selectList"
			@moveSuccess="moveSuccess"
		></move-dialog>
	</el-dialog>
</template>
<script>
import moveDialog from './moveDialog';
export default {
	components: {
		moveDialog
	},
	props: {
		dialogVisible: {
			type: Boolean
		},
		organizeList: {
			type: Array
		},

		organizeParams: {
			type: Object
		}
	},
	data() {
		return {
			loading: false,
			moveDialog: false,
			staffList: [],
			keyWork: '',
			totalNum: 0,
			pageNo: 1,
			title: '组织名称',
			disabled: true,
			selectList: []
		};
	},
	mounted() {},
	methods: {
		openDialog() {
			this.$nextTick(() => {
				this.$refs.multipleTable.clearSelection();
			});
			(this.pageNo = 1), (this.keyWork = '');
			this.getStaffList();
		},
		query() {
			this.getStaffList();
		},
		getRowKey(row) {
			return row.staffId;
		},
		moveSuccess() {
			this.$parent.getList();
			this.$emit('update:dialogVisible', false);
		},
		// 获取员工列表
		getStaffList() {
			this.loading = true;
			this.$axios
				.post('/v1/org/department/staff/list', {
					request: {
						pageNo: this.pageNo,
						pageSize: 10,
						orgId: this.organizeParams.id,
						keyWork: this.keyWork
					},
					sign: ''
				})
				.then((res) => {
					this.totalNum = Number(res.totalNum);
					this.staffList = res.list;
					this.loading = false;
				});
		},
		// 表格选择
		handleSelectChange(row) {
			this.selectList = row;
		},
		// 点击调整
		commitEnter() {
			if (this.selectList.length === 0) {
				// this.disabled
				return this.$message.error('请选择员工!');
			}
			this.moveDialog = true;
		},
		handleCurrentChange(val) {
			this.pageNo = val;
			this.getStaffList();
			// console.log(`当前页: ${val}`);
		},
		cancle() {
			this.$emit('update:dialogVisible', false);
			//   this.$refs.multipleTable.clearSelection()
		},
		handleClose() {
			this.$emit('update:dialogVisible', false);
			//   this.$refs.multipleTable.clearSelection()
		}
	}
};
</script>
<style lang="scss">
.search_box {
	margin-bottom: 20px;
}
.search_icon {
	padding-right: 15px;
}
</style>
