<template>
	<div class="orgSet">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="toback">返回</el-button>
			<div class="title">组织架构调整申请</div>
		</div>
		<el-row style="margin-bottom: 20px; margin-top: 10px">
			<div class="selectinput">
				<el-input readonly="readonly" value="组织" size="small" style="width: 80px"></el-input>
				<el-input v-model="search" size="small" placeholder="请输入组织名称"></el-input>
			</div>
			<el-button type="primary" size="small" icon="el-icon-share" @click="viewMap" style="float: right">组织架构图</el-button>
		</el-row>
		<el-table
			:data="tableData"
			ref="treeTable"
			v-loading="loading"
			style="width: 100%; margin-bottom: 20px"
			row-key="id"
			default-expand-all
			class="tableBackground"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<template v-for="item in columns">
				<el-table-column :key="item.key" :label="item.label" :width="getWidthByKey(item.key)" :prop="item.key" v-if="item.key === 'orgName'">
					<template slot-scope="scope">
						<span style="margin-right: 30px">{{ scope.row.orgName }}</span>

						<span class="operate_row" v-if="isEdit">
							<span @click="handleAdd(scope.row)">新增</span>
							<span @click="handleEdit(scope.row)">编辑</span>
							<!-- <span @click="checkStaff(scope.row)">{{ scope.row.orgPid == 0 ? '待分配员工' : '员工' }}</span> -->
							<span @click="handleMove(scope.row)" v-show="scope.row.orgPid != 0">移动</span>
							<span @click="handleDelete(scope.row)" v-show="scope.row.orgPid != 0" style="color: #fe1c1c">删除</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column
					:key="'_' + item.key"
					:label="item.label"
					:width="getWidthByKey(item.key)"
					:prop="item.key"
					v-else-if="item.key === 'orgFunction'"
				>
					<template slot-scope="scope">
						<el-popover placement="bottom-start" trigger="hover" width="800" @show="() => showOrgFuncTion(scope)">
							<baseTable
								v-if="scope.$index === showIndex || popLoading"
								:dataList="viewData"
								:columns="viewColumns"
								:showPagination="false"
								:spanMethod="spanMethod"
								v-loading="popLoading"
							/>
							<div slot="reference" class="contentItem">{{ scope.row.orgFunction || '-' }}</div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column align="center" :key="'__' + item.key" :label="item.label" :width="getWidthByKey(item.key)" :prop="item.key" v-else>
				</el-table-column>
			</template>
		</el-table>
		<el-row style="text-align: center; padding: 20px; background-color: #fff">
			<el-button @click="toback">取消</el-button>
			<el-button type="primary" @click="submit">下一步</el-button>
		</el-row>
		<!-- 新增/编辑 弹框 -->
		<edit-dialog
			:dialogVisible.sync="showEditDialog"
			:operateType="operateType"
			:ruleForm="editForm"
			:selectedCityArr="selectedCity"
			:theTop="top"
			:isApproval="true"
			@change="editChange"
		></edit-dialog>

		<staff-list :dialogVisible.sync="staffDialog" :organizeParams="organizeParams" :organizeList="treeSelectData"></staff-list>

		<!-- 移动弹框 -->
		<move-dialog
			:dialogVisible.sync="moveDialog"
			:organizeList="treeSelectData"
			:organzieId="organzieId"
			:lable="title"
			:isApproval="true"
			@callBack="getMoveData"
			:orgList="tableDataList"
		></move-dialog>
	</div>
</template>
<script>
import axios from 'axios';
import editDialog from '../../../organizingpersonnel/organizing/components/editDialog.vue';
import moveDialog from '../../../organizingpersonnel/organizing/components/moveDialog.vue';
import staffList from '../../../organizingpersonnel/organizing/components/staffList.vue';
import Sortable from 'sortablejs';
import { setUrlParams, setStore, getStore } from '@/utils/util';
import { cloneDeep } from 'loadsh';
import { Loading } from 'element-ui';
import baseTable from '@/views/components/components-base-table';

export default {
	components: {
		baseTable,
		editDialog,
		moveDialog,
		staffList
	},
	props: {
		// search: {
		// 	type: String,
		// 	default: ''
		// },
		activeTab: { type: String }
	},
	data() {
		return {
			top: '',
			title: '上级组织',
			loading: false,
			showEditDialog: false,
			moveDialog: false,
			staffDialog: false,
			hover: true,
			data: {},
			treeSelectData: [],
			organzieId: '',
			table: [],
			tableData: [],
			expendRows: [],
			operateType: '',
			editForm: {},
			selectedCity: [],
			organizeParams: {
				id: '',
				title: ''
			},
			columns: [],
			viewData: [],
			viewColumns: [
				{ label: '一级职能', key: 'functionDesc1' },
				{
					label: '二级职能',
					key: 'functionsTwo',
					render: (e) => {
						if ([2, 3].includes(e.functionLevel)) {
							return <span>{e.functionDesc}</span>;
						}
						return <div></div>;
					}
				},
				{
					label: '三级职能',
					key: 'functionsThree',
					children: [
						{
							label: '计划/规划',
							key: 'plan'
						},
						{
							label: '组织/实施',
							key: 'enforce'
						},
						{
							label: '协调/配合',
							key: 'cohere'
						},
						{
							label: '指导/监控',
							key: 'monitor'
						}
					]
				}
			],
			popLoading: false,
			showIndex: null,
			search: '',
			orginData: {}
		};
	},
	computed: {
		tableDataList() {
			return this.pavingData(this.tableData);
		},
		isEdit() {
			return true;
		},
		getWidthByKey() {
			return (key) => {
				let w = '';

				switch (key) {
					case 'orgName':
						w = this.isEdit ? '400px' : '280px';
						break;

					default:
						break;
				}
				return w;
			};
		},
		query() {
			return this.$route.query;
		}
		// options() {
		// 	return this.$store.state.app.dict.filter((item) => item.groupId == 'informal');
		// }
	},
	created() {
		this.getList();
		this.findOrgPermission();
	},
	mounted() {
		if (this.isEdit) {
			this.changeTableRowSort();
		}
	},
	watch: {
		search: {
			handler(val) {
				if (val) {
					this.expendRows = [];
					this.tableData = this.searchData(this.table, val);
					this.expendRows.forEach((v) => {
						this.$refs.treeTable.toggleRowExpansion(v, true);
					});
				} else {
					this.tableData = this.table;
				}
			},
			immediate: true
		}
	},
	methods: {
		// 权限限制
		findOrgPermission() {
			this.$axios.post('/v1/org/findOrgPermission', { request: {} }).then((res) => {
				if (res?.code) {
					return;
				}
				let cols = res || [];

				// if (this.isApproval) {
				cols = res?.filter(({ key }) => ['orgName', 'orgLevel', 'cityName'].includes(key)) || [];
				// }
				this.columns = cols;
			});
		},
		cellStyle({ row, column, rowIndex, columnIndex }) {
			if (columnIndex == 0) {
				return 'org_name';
			}
			if ((row, column, rowIndex)) {
				//   console.log('');
			}
		},
		searchData(data, val) {
			const newData = [];

			data.forEach((v) => {
				if (v.children && v.children.length > 0) {
					let flag = false;

					this.expendRows.push(v);
					if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
						flag = true;
						newData.push(v);
					}
					const children = this.searchData(v.children, val);
					const obj = {
						...v,
						children
					};

					if (children && children.length && !flag) {
						newData.push(obj);
					}
				} else if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
					this.expendRows.push(v);
					newData.push(v);
				}
			});
			return newData;
		},
		getList() {
			this.loading = true;
			axios.post('/v1/org/tempList', { request: {} }).then((res) => {
				if (res[0]) {
					this.data = res[0];
					this.treeSelectData = [res[0]];
					this.tableData = this.table = [res[0]];
					this.$store.commit('SET_DEPT', res);
				}
				this.loading = false;
			});
		},
		// 查看架构图
		checkTree(row) {
			this.$router.push({
				path: '/organizeMap',
				query: {
					params: setUrlParams(row)
				}
			});
		},

		// 新增
		handleAdd(row) {
			this.top = '';
			this.selectedCity = [];
			this.operateType = '添加组织';
			this.editForm = {
				orgPid: '',
				orgType: '',
				orgPrinId: '',
				orgName: '',
				orgCode: '',
				orgPrinName: '',
				provinceCode: '',
				cityCode: '',
				remark: ''
			};
			if (row) {
				this.editForm.orgPid = row.id;
			}
			this.showEditDialog = true;
		},
		// 编辑
		handleEdit(row) {
			// this.$refs.editDialog.handleClose();
			this.selectedCity = [];

			this.operateType = '编辑组织';
			if (row) {
				this.top = row.orgPid == 0 ? 'top' : '';
				this.organzieId = row.id;

				this.selectedCity.push(String(row.provinceCode || ''), String(row.cityCode || ''));

				this.editForm = {
					id: row.id,
					orgPid: row.orgPid,
					orgType: row.orgType,
					orgPrinId: row.orgPrinId,
					orgName: row.orgName,
					orgCode: row.orgCode,
					orgPrinName: row.orgPrinName,
					provinceCode: row.provinceCode,
					cityCode: row.cityCode,
					employeesNumber: row.employeesNumber,
					monthEndNumber: row.monthEndNumber,
					remark: row.remark
				};
				this.orginData = { ...row };
			}
			this.showEditDialog = true;
		},
		// 移动
		handleMove(row) {
			this.organzieId = row.id;
			this.moveDialog = true;
		},
		// 员工
		checkStaff(row) {
			if (row) {
				this.organizeParams.title = row.orgName;
				this.organizeParams.id = row.id;
			}
			this.staffDialog = true;
		},
		// 删除
		handleDelete(row) {
			this.$confirm('是否确定删除该组织?', `${row.orgName},已绑定人数:${Number(row.officialNumber) + Number(row.informalityNumber)}`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				axios
					.post('/v1/org/deleteTemp', {
						request: { id: Number(row.id) },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.getList();
							// 记录操作
							this.changeOrgRecord('delete', row);
						} else {
							// this.$message.error(res.message)
						}
					});
			});
		},
		// 将嵌套数据平铺
		pavingData(list) {
			if (!list?.length) {
				return [];
			}
			let arr = [];

			if (list && list.length) {
				list.forEach((data) => {
					const { children } = data;

					if (children?.length) {
						arr.push({ ...data });
						arr = arr.concat(this.pavingData(children));
					} else {
						arr.push({ ...data, children });
					}
				});
			}
			return arr;
		},
		// 获取所有子节点 通过id
		getAllChildIds(id, allList) {
			const childs = allList.filter(({ orgPid }) => id === orgPid);

			let arr = [];

			if (childs?.length) {
				arr = arr.concat(childs);

				childs.forEach(({ id }) => {
					arr = [...arr, ...this.getAllChildIds(id, allList)];
				});
			}

			return arr;
		},
		// 将平铺数据转嵌套
		nestData(list) {
			if (!list?.length) {
				return [];
			}
			const arr = [];

			if (list?.length) {
				let alreadyIds = [];

				list.forEach((data) => {
					if (!alreadyIds.includes(data.id)) {
						const allChild = this.getAllChildIds(data.id, this.newDataList);

						if (allChild?.length) {
							alreadyIds = alreadyIds.concat(allChild.map(({ id }) => id));
							arr.push({ ...data, children: this.nestData(allChild) });
						} else {
							arr.push({ ...data, children: [] });
						}
					}
				});
			}
			return arr;
		},
		// table行移动
		changeTableRowSort() {
			this.$nextTick(() => {
				const _this = this;

				this.drop = Sortable.create(document.querySelector('.el-table .el-table__body-wrapper tbody'), {
					// handle:'.expanded',
					animation: 180,
					delay: 0,

					onStart(e) {
						const list = cloneDeep(_this.tableDataList);
						const dragRow = list[e.oldIndex];

						// 拖动目标有子集时关闭展开状态
						if (dragRow?.children?.length) {
							_this.$refs.treeTable.toggleRowExpansion(dragRow, false);
						}
					},
					onEnd(evt) {
						if (evt.newIndex === 0 || evt.oldIndex === 0 || evt.oldIndex === evt.newIndex) {
							if (evt.oldIndex === evt.newIndex) {
								return;
							}
							const _orgData = cloneDeep(_this.tableData);

							_this.tableData = [];
							setTimeout(() => {
								_this.tableData = _orgData;
							}, 0);
							return;
						}
						// 平铺数据
						const list = cloneDeep(_this.tableDataList);
						const targetRow = cloneDeep(list[evt.newIndex]);
						const oldRow = cloneDeep(list[evt.oldIndex]);

						list.splice(evt.oldIndex, 1);
						list.splice(evt.newIndex, 0, { ...oldRow, orgPid: targetRow.orgPid });
						_this.newDataList = list;
						const loading = Loading.service({ target: '.orgSet' });
						const orgList = list.filter(({ orgPid }) => orgPid === targetRow.orgPid).map((val, inx) => ({ ...val, sortNo: inx + 1 }));

						_this
							.submitMove({
								id: oldRow.id,
								pid: targetRow.orgPid,
								orgListResponses: orgList
							})
							.then((res) => {
								_this.tableData = [];
								loading.close();
								if (res) {
									setTimeout(() => {
										// console.log(targetRow, orgList, 'orgList');
										_this.changeOrgRecord('move', { target: targetRow, origin: oldRow });
										_this.tableData = _this.nestData(list);
									}, 0);
								} else {
									const _orgData = [..._this.tableData];

									setTimeout(() => {
										_this.tableData = _orgData;
									}, 0);
								}
							})
							.catch(() => {
								const _orgData = [..._this.tableData];

								_this.tableData = [];
								setTimeout(() => {
									_this.tableData = _orgData;
								}, 0);
								loading.close();
							});
					}
				});
			});
		},
		// 移动提交
		submitMove(params) {
			return this.$axios.post('/v1/org/moveTemp', { request: params }).then((res) => {
				if (res?.code === 0) {
					this.$message.success('移动成功');
					return true;
				}
				return false;
			});
		},
		// 查询组织职能
		getOrgFunction(e) {
			return this.$axios
				.post('/v1/org/findOrgFunctionByOrgId', { request: { orgId: e.orgId } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		},
		// eslint-disable-next-line no-unused-vars
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0 && columnIndex === 0) {
					return [this.viewData.length, 1];
				}
				return [0, 0];
			}
		},
		async showOrgFuncTion({ row: data, $index }) {
			this.showIndex = $index;
			this.popLoading = true;
			const res = await this.getViewDetail(data.id);

			this.popLoading = false;
			this.viewData =
				res.orgFunctions?.map((val) => {
					const [plan, enforce, cohere, monitor] = [
						val.orgFunctions[0]?.functionDesc,
						val.orgFunctions[1]?.functionDesc,
						val.orgFunctions[2]?.functionDesc,
						val.orgFunctions[3]?.functionDesc
					];

					return { ...val, cohere, enforce, monitor, plan, functionDesc1: res.functionDesc };
				}) || [];
		},
		// 查询组织职能详情
		getViewDetail(e) {
			return this.$axios
				.post('/v1/org/findOrgFunctionByOrgId', { request: { orgId: e } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		},
		selectChange(e) {
			this.getList(e);
		},
		viewMap() {
			this.$router.push({
				path: '/organizeMap',
				query: {
					params: setUrlParams({
						isEdit: this.isEdit,
						isApproval: true,
						checkedKeys: ['orgLevel', 'cityName'],
						approvalId: this.query.approvalId,
						subType: this.query.subType
					})
				}
			});
		},
		// 修改记录
		changeOrgRecord(type, e) {
			this.$nextTick(() => {
				let oldList = getStore(`orgRecords_${this.query.approvalId}`);

				if (oldList) {
					oldList = JSON.parse(oldList);
				} else {
					oldList = [];
				}
				if (type === 'add') {
					oldList = oldList.concat({ ...e, changeType: 'add', orgPname: this.tableDataList.find(({ id }) => e.orgPid === id)?.orgName });
				} else if (type === 'edit') {
					// 编辑
					const current = oldList.filter(({ id, changeType }) => id === e.id && type === changeType);

					// 已经修改过
					if (current?.length) {
						// const originOrg = current[0];

						oldList = oldList.map((it) => {
							if (type === it.changeType && it.id === e.id) {
								return { ...it, ...e };
							}
							return it;
						});
					} else {
						// 未修改的直接新增

						// 新增过后直接编辑的
						const currentAdd = oldList.filter(({ changeType, orgName }) => orgName === this.orginData.orgName && changeType === 'add');

						if (currentAdd?.length) {
							oldList = oldList.map((val) => {
								if (val.orgName === this.orginData.orgName && val.changeType === 'add') {
									return { ...val, ...this.orginData, changeType: 'add', orgName: e.orgName };
								}
								return val;
							});
						} else {
							oldList = oldList.concat({ ...e, orginData: { ...this.orginData }, changeType: 'edit' });
						}

						this.orginData = {};
					}
				} else if (type === 'move') {
					const { origin, target } = e;

					// 调整到同一父级不记录
					if (origin?.orgPid === target?.orgPid) {
						return;
					}
					// 是否已有储存
					const current = oldList.filter(({ id, changeType }) => id === origin.id && type === changeType);
					const pRow = this.tableDataList.find(({ id }) => id === target.orgPid) || {};

					if (current?.length) {
						oldList = oldList.map((it) => {
							if (type === it.changeType && it.id === origin.id) {
								return { ...it, orgPdata: pRow };
							}
							return it;
						});
					} else {
						oldList = oldList.concat({ ...origin, orgPdata: pRow, changeType: 'move' });
					}
				} else if (type === 'delete') {
					// 新增过后 删除
					const currentAdd = oldList.filter(
						({ changeType, orgName, id }) => (orgName === e.orgName || id === e.id) && changeType === 'add'
					);

					if (currentAdd?.length) {
						oldList = oldList.filter(({ changeType, orgName, id }) => !((orgName === e.orgName || id === e.id) && changeType === 'add'));
					} else {
						oldList = oldList.concat({ ...e, changeType: 'delete' });
					}
				}
				setStore(`orgRecords_${this.query.approvalId}`, oldList);
			});
		},
		editChange(type, e) {
			const realType = type === '添加组织' ? 'add' : 'edit';

			this.changeOrgRecord(realType, e);
		},
		toback() {
			const loading = Loading.service({ target: '.orgSet' });

			this.cancelApply().then((res) => {
				loading.close();
				if (res) {
					setStore(`orgRecords_${this.query.approvalId}`, '');
					this.$router.go(-1);
				}
			});
		},
		// 点击取消或上一步
		cancelApply() {
			return this.$axios
				.post('/v1/approval/user/cancelApply', { request: { approvalInfoId: this.query.approvalId } })
				.then((res) => {
					if (res.code) {
						return false;
					}
					return true;
				})
				.catch(() => {});
		},
		getMoveData({ origin, pid }) {
			this.changeOrgRecord('move', { origin, target: { orgPid: pid } });
		},
		submit() {
			console.log('submit');
			this.$router.push({
				path: '/staff/orgSubmit',
				query: {
					approvalId: this.query.approvalId,
					subType: this.query.subType
				}
			});
		}
	},
	beforeDestroy() {
		console.log('beforeDestroy');
	}
};
</script>
<style lang="scss">
.orgSet {
	padding: 30px;
	// background: #fff;
	.el-message-box__status {
		top: 20%;
	}
	.selectinput {
		// position: absolute;
		// right: 204px;
		// top: 24px;
		display: inline-block;
		margin-right: 30px;
		z-index: 5;
		border: 1px solid #ccc;
		.el-input__inner {
			border: none;
		}
		.el-select:first-of-type {
			width: 103px;
			.el-input__inner {
				text-align: center;
			}
		}
		> .el-input {
			width: 160px;
		}
	}
}
</style>
<style lang="scss" scoped>
.container {
	min-height: 600px;
}
.icon {
	color: #4a8df0;
}
.operate_row {
	color: #0066ff;
	font-size: 13px;
	span {
		margin-right: 8px;
		cursor: pointer;
	}
}
.operate_row {
	display: none;
}
tr:hover {
	.operate_row {
		display: inline;
	}
}
</style>
<style>
.org_name {
	padding-left: 15px !important;
}
/* .popper_tooltip_class.el-tooltip__popper.is-light {
  border: 1px solid #0066ff;
  color: #333;
  background-color: #fff;
} */
</style>
