<template>
	<el-dialog
		width="500px"
		center
		top="5vh"
		:title="operateType"
		:visible.sync="dialogVisible"
		:before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@open="openDialog"
		class="form_dialog_style"
	>
		<!-- :before-close="resetForm('ruleForm')" -->
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="组织名称" prop="orgName">
				<el-input v-model="ruleForm.orgName"></el-input>
			</el-form-item>
			<el-form-item label="组织编码" prop="orgCode">
				<el-input v-model="ruleForm.orgCode"></el-input>
			</el-form-item>
			<!-- <el-form-item label="组织类型"
                    v-show="theTop != 'top'"
                    prop="orgType">
        <el-select placeholder="请选择组织类型"
                   style="width:100%;"
                   filterable
                   v-model="ruleForm.orgType">
          <el-option v-for="item in orgTypeList"
                     :key="item.dictId"
                     :label="item.dictName"
                     :value="item.dictId"></el-option>
        </el-select>
      </el-form-item> -->
			<!-- <el-form-item label="编制人数" prop="employeesNumber" v-if="!isApproval">
				<el-input v-model="ruleForm.employeesNumber" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入编制人数"></el-input>
			</el-form-item>
			<el-form-item label="月末人数" prop="monthEndNumber" v-if="!isApproval">
				<el-input v-model="ruleForm.monthEndNumber" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入月末人数"></el-input>
			</el-form-item>
			<el-form-item label="组织负责人" prop="orgPrinName" v-if="!isApproval">
				<el-select placeholder="请选择组织负责人" style="width: 100%" filterable v-model="ruleForm.orgPrinName" @change="staffSelect($event)">
					<el-option v-for="item in staffList" :key="item.staffId" :label="item.staffName" :value="item.staffId">
						<span style="float: left">{{ item.officeName }}</span>
						<span style="float: right; color: #8492a6; font-size: 13px">{{ item.staffName }}</span>
					</el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="组织城市">
				<!-- {{selectedCity}} -->
				<el-cascader :options="cityOptions" v-model="selectedCity" separator=" " filterable clearable style="width: 100%"></el-cascader>
			</el-form-item>
			<!-- <el-form-item label="组织说明" prop="remark" v-if="!isApproval">
				<el-input type="textarea" maxlength="200" v-model="ruleForm.remark"></el-input>
			</el-form-item> -->
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="cancle">取 消</el-button>
			<el-button type="primary" @click="commitForm('ruleForm')">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import axios from 'axios';
// import { close } from 'fs';
/* eslint-disable */
export default {
	props: {
		// 审批申请页面
		isApproval: {
			type: Boolean
			// required: true
		},
		dialogVisible: {
			type: Boolean
			// required: true
		},
		selectedCityArr: {
			type: Array
			// required: true
		},
		operateType: {
			type: String
			// required: true
		},
		ruleForm: {
			type: Object
			// required: true
		},
		theTop: {
			type: String
		}
	},
	watch: {},
	data() {
		return {
			rules: {
				orgName: [
					{ required: true, type: 'string', message: '请输入组织名称', trigger: 'blur' },
					{ min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
				],
				orgCode: [
					{ required: true, type: 'string', message: '请输入组织编码', trigger: 'blur' },
					{ min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
				],
				orgType: [{ required: true, type: 'string', message: '请选择组织类型', trigger: 'change' }]
			},
			// orgTypeList: [],
			staffList: [],
			cityOptions: [],
			selectedCity: []
			// selectedCityarr: [],
		};
	},
	mounted() {
		this.getCityList();
		// this.getTypeList();
		this.getSelectPrincipal();
	},
	methods: {
		openDialog() {
			this.selectedCity = this.selectedCityArr;
			// console.log("this.selectedCity", this.selectedCity);
			this.$nextTick(() => {
				this.$refs['ruleForm'].clearValidate();
			});
		},
		// 获取城市
		getCityList() {
			this.cityOptions = window.$cityList;
		},
		// 获取组织类型
		// getTypeList () {
		//   axios
		//     .get('/v1/common/dict')
		//     .then((res) => {
		//       this.orgTypeList = res.filter((item) => item.groupId == 'orgType');
		//     });

		// },
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 提交新增
		commitForm(formName) {
			// this.ruleForm.orgPid = this.organzieId;
			this.ruleForm.provinceCode = this.selectedCity[0] || '';
			this.ruleForm.cityCode = this.selectedCity[1] || '';
			let tooltip, url;
			//  console.log("ruleForm",this.ruleForm);

			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.operateType == '添加组织') {
						url = '/v1/org/add';
						tooltip = '添加成功';
						if (this.isApproval) {
							url = '/v1/org/addTemp';
						}
					}
					if (this.operateType == '编辑组织') {
						url = '/v1/org/update';
						tooltip = '编辑成功';
						if (this.isApproval) {
							url = '/v1/org/updateTemp';
						}
					}
					//     console.log("organzieId",this.organzieId);

					axios
						.post(url, {
							request: this.ruleForm,
							sign: ''
						})
						.then((res) => {
							if (res && res.code == 0) {
								const prov = this.cityOptions?.find(({ value }) => value === this.ruleForm?.provinceCode);
								const city = prov?.children?.find(({ value }) => value === this.ruleForm?.cityCode)?.label;
								this.$emit('change', this.operateType, { ...this.ruleForm, cityName: `${prov?.label}-${city}` });

								this.$message.success(tooltip);
								this.handleClose();
								this.$parent.getList();
							} else {
								// this.$message.error(res.message);
							}
						});
				} else {
					return false;
				}
			});
		},
		cancle() {
			this.handleClose();
		},
		handleClose() {
			this.$emit('update:dialogVisible', false);
			this.$nextTick(() => {
				this.$refs['ruleForm'].clearValidate();
				this.$refs['ruleForm'].resetFields(); // this.$refs.adduserform.resetFields();
			});
		},
		// 获取组织负责人
		getSelectPrincipal() {
			axios
				.post('/v1/common/selectPrincipal/list', {
					request: {
						staffName: ''
					},
					sign: ''
				})
				.then((res) => {
					this.staffList = res;
				});
		},
		// 选择组织负责人
		staffSelect(e) {
			this.ruleForm.orgPrinId = e;

			this.ruleForm.orgPrinName = this.staffList.find((item) => item.staffId == e).staffName;
		}
	}
};
</script>
<style lang="scss" scoped>
.el-form-item__content {
	width: 100%;
}
</style>
