<template>
	<!-- 创建弹框 -->
	<el-dialog
		width="500px"
		append-to-body
		center
		title="调整组织"
		:visible.sync="dialogVisible"
		:before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@open="openDialog"
	>
		<div style="margin-bottom: 25px">
			<span class="input_lable">{{ lable }}</span>
			<!-- 调用树形下拉框组件 -->
			<SelectTree
				:props="props"
				:options="organizeList"
				:value="valueId"
				:clearable="isClearable"
				:accordion="isAccordion"
				@getValue="getValue($event)"
				style="width: 70%"
			/>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="cancle">取 消</el-button>
			<el-button type="primary" @click="commitForm('ruleForm')">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import SelectTree from './treeSelect.vue';
// import { lookup } from 'dns';
export default {
	components: {
		SelectTree
	},
	props: {
		lable: {
			type: String,
			default() {
				('上级组织');
			}
		},
		dialogVisible: {
			type: Boolean
		},
		organizeList: {
			type: Array
		},
		selectList: {
			type: Array,
			default() {
				// eslint-disable-next-line no-unused-expressions
				[];
			}
		},
		organzieId: {
			type: String
		},
		orgList: {
			type: Array,
			default: () => []
		},
		// 审批申请页面
		isApproval: {
			type: Boolean
			// required: true
		}
	},
	data() {
		return {
			isClearable: true, // 可清空（可选）
			isAccordion: true, // 可收起（可选）
			valueId: '', // 初始ID（可选）
			props: {
				// 配置项（必选）
				value: 'id',
				label: 'orgName',
				children: 'children'
				// disabled:true
			},
			// 选项列表（必选）
			list: []
		};
	},
	mounted() {
		//    this.list = [this.organizeList]
	},
	computed: {
		/* 转树形数据 */
		optionData() {
			const cloneData = JSON.parse(JSON.stringify(this.list)); // 对源数据深度克隆

			return cloneData.filter((father) => {
				// 循环所有项，并添加children属性
				const branchArr = cloneData.filter((child) => father.id == child.parentId); // 返回每一项的子级数组

				// eslint-disable-next-line no-unused-expressions
				branchArr.length > 0 ? (father.children = branchArr) : ''; // 给父级添加一个children属性，并赋值
				return father.parentId == 0; // 返回第一层
			});
		}
	},
	methods: {
		// 取值
		getValue(value) {
			this.valueId = value;
			// console.log(this.valueId);
		},
		openDialog() {
			this.valueId = this.organzieId;
			//   console.log('this.$parent', this.$parent.$parent);
		},

		cancle() {
			this.$emit('update:dialogVisible', false);
		},
		handleClose() {
			this.$emit('update:dialogVisible', false);
		},
		commitForm() {
			if (!this.valueId) {
				return this.$message.error('请选择组织');
			}

			if (this.isApproval) {
				// '/v1/org/moveTemp'
				return this.$axios
					.post('/v1/org/moveTemp', {
						request: {
							id: this.organzieId,
							pid: this.valueId
						},
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('移动成功!');
							this.$emit('update:dialogVisible', false);
							this.$parent.getList();
							this.$emit('callBack', { origin: this.orgList.find(({ id }) => id === this.organzieId), pid: this.valueId });
						}
					});
			}
			if (this.lable == '组织名称') {
				const selectId = [];

				this.selectList.forEach((item) => {
					selectId.push(item.staffId);
				});

				// console.log('selectId', selectId);
				this.$axios
					.post('/v1/org/adjStaffDepartment', {
						request: {
							staffIds: selectId,
							orgId: this.valueId
						},
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('调整成功!');
							this.$emit('update:dialogVisible', false);
							this.$parent.$parent.getStaffList();
							this.$emit('moveSuccess');
						}
					});
			} else {
				this.$axios
					.post('/v1/org/move', {
						request: {
							id: this.organzieId,
							pid: this.valueId
						},
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('移动成功!');
							this.$emit('update:dialogVisible', false);
							this.$parent.getList();
						}
						// else {
						//   this.$message.error(res.message);
						// }
					});
			}

			// console.log("this.list",this.list);
		}
	}
};
</script>
<style lang="scss" scoped>
.input_lable {
	margin-right: 25px;
	font-size: 16px;
	color: #333333;
}
</style>
